import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import medicalPDF from '../../static/docs/medical-history-form-SDA.pdf';
import newPatientPDF from '../../static/docs/new-patient-form-SDA.pdf';

const NewPatients = (props) => {
  return (
    <Layout bodyClass='page-teams'>
      <SEO title='New Patients' />
      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-12 col-lg-12 order-1'>
              <h1>New Patients</h1>
              <div className='pt-6' style={{ borderTop: '1px solid black' }}>
                <p>At this time, we are not accepting new patients. We will update this site with any changes</p>
                <p>
                  <strong>
                    {' '}
                    If you have insurance: have name of carrier, any group numbers, id numbers, and claim address ready
                    to give to the receptionist.
                  </strong>
                </p>
                <p>
                  <strong> On your first visit, be sure to bring:</strong>
                </p>
                <ul className='pl-2'>
                  <li>
                    <p> Your dental insurance card.</p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      Any x-rays you've had taken in the past five years. You can obtain your old x-rays simply by
                      calling your previous dentist and requesting your "most recent bite wings and full mouth set."
                    </p>
                  </li>
                  <li>
                    <p>
                      Our two new patient forms, filled out. Those can be found below. Bringing these forms filled out
                      will greatly decrease your wait time in our reception area. If you encounter problems downloading
                      or printing, our business office can supply you with these forms.
                    </p>
                  </li>
                </ul>
                <div className='pt-2'>
                  <a href={medicalPDF} className='button mr-3 px-3'>
                    <div>Medical History Form</div>
                  </a>
                  <a href={newPatientPDF} className='button px-3'>
                    <div>New Patient Form</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewPatients;
