import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const InsuranceFinancing = (props) => {
  return (
    <Layout bodyClass='page-teams'>
      <SEO title='Insurance and Financing' />
      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-12 col-lg-12 order-1'>
              <h1>Insurance and Financing</h1>
              <div className='pt-6' style={{ borderTop: '1px solid black' }}>
                <p>
                  <strong>
                    <u>Payment Options:</u>
                  </strong>
                </p>
                <p className='pb-3'>
                  For your convenience, we accept Cash, Personal Checks, Credit Cards (Visa, MasterCard, Discover,
                  American Express) Care Credit, and Money Orders. Payment is expected the same day as services are
                  performed. We also participate with Care Credit.
                </p>
                <p>
                  <strong>
                    <u>More about Care Credit:</u>
                  </strong>
                </p>
                <p className='pb-3'>
                  Care Credit is a great financing option for patients who need payment plans. See{' '}
                  <a href='carecredit.com/apply'>carecredit.com/apply</a> or call 800-365-8295
                </p>
                <p>
                  <strong>
                    <u>Insurance</u>
                  </strong>
                </p>
                <p>
                  As a service to our patients, our practice accepts many dental insurance programs, and you can find
                  the list below. Our business staff will prepare all the necessary forms for your dental benefits.
                  However, we remind you that your specific policy is an agreement between you and your insurance
                  company. Please keep in mind that you are responsible for your total obligation should your insurance
                  benefits result in less coverage than anticipated. Our staff will gladly submit a pre-treatment
                  estimate to your insurance company so that you will know what your expense will be.{' '}
                </p>
                <p className='pb-3'>
                  The fees charged for services rendered to those who are insured are the usual and customary fees
                  charged to all our patients for similar services. Your policy may base its allowances on a fixed fee
                  schedule, which may or may not coincide with our usual fees. You should be aware that different
                  insurance companies vary greatly in the types of coverage available. Also, some companies take care of
                  claims promptly while others delay payment for several months.
                </p>
                <p>
                  <strong>
                    <u>Insurances Accepted:</u>
                  </strong>
                </p>
                <ul className='pl-2 pb-3'>
                  <li>
                    <p>Anthem</p>
                  </li>
                  <li>
                    <p>Ameritas</p>
                  </li>
                  <li>
                    <p>Dentemax</p>
                  </li>
                  <li>
                    <p>Dental benefit providers</p>
                  </li>
                  <li>
                    <p>Excellus (no essential plans, no CNY Preferred plans)</p>
                  </li>
                  <li>
                    <p>Excellus Medicare Blue Classic PPO</p>
                  </li>
                  <li>
                    <p>GEHA</p>
                  </li>
                  <li>
                    <p>Guardian</p>
                  </li>
                  <li>
                    <p>Liberty Dental</p>
                  </li>
                  <li>
                    <p>MetLife PPO</p>
                  </li>
                  <li>
                    <p>Mutual Of Omaha</p>
                  </li>
                  <li>
                    <p>Principal</p>
                  </li>
                  <li>
                    <p>Teamsters</p>
                  </li>
                  <li>
                    <p>SIEBA</p>
                  </li>
                  <li>
                    <p>Sun Life</p>
                  </li>
                  <li>
                    <p>United Healthcare</p>
                  </li>
                  <li>
                    <p>United Healthcare medicare advantage (AARP)</p>
                  </li>
                  <li>
                    <p>United Concordia / Tricare / Active Duty</p>
                  </li>
                </ul>
                <p>
                  <strong>
                    <u>Sorry, we do not accept:</u>
                  </strong>
                </p>
                <p>
                  {' '}
                  Medicaid plans, Humana, United Healthcare community plan, Healthplex, Totalcare, Fidelis, Molina and
                  none of the Essential plans on the NYS exchange website. We also do not accept DMO plans.
                </p>
                <p>
                  <strong>
                    Don't see your plan listed here? Call us. We can still take care of your dental needs. We offer
                    financing options through Care Credit.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InsuranceFinancing;
